import React from 'react'
import HeroSection from './HeroSection'
import { Box } from '@mui/material'
import Section2 from './Section2'
import Section3 from './Section3'
import Section4 from './Section4'
function HomePage() {
  return (
    <Box>
        
        <HeroSection />
        <Section2 />
        <Section3 />
        <Section4 />
    </Box>
  )
}

export default HomePage