import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
  Fade,
} from "@mui/material";
import { motion } from "framer-motion";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../utils/firebase";

function RegistrationModal({ email }) {
  const [name, setName] = useState("");
  const [organization, setOrganization] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Add security headers and metadata
      const metadata = {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "X-Forwarded-For": window.location.hostname,
        },
        customMetadata: {
          source: "web-registration",
          timestamp: Date.now(),
        },
      };

      const docRef = await addDoc(collection(db, "users"), {
        name: name,
        email: email,
        organization: organization,
        createdAt: new Date(),
        metadata: metadata,
      });
      console.log("Document written with ID:", docRef.id);
      setIsSubmitting(false);
      setSubmitted(true);
    } catch (error) {
      console.error("Error adding document:", error);
      setIsSubmitting(false);
      // You might want to add error handling UI here
    }
  };

  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      sx={{
        display: "flex",
        flexDirection: "column",
        bgcolor: "white",
        borderRadius: "16px",
        overflow: "hidden",
        boxShadow: "0 10px 25px rgba(0,0,0,0.1)",
        maxWidth: "450px",
        width: "100%",
        mx: "auto",
        p: { xs: 1.5, sm: 3 },
        position: "relative",
      }}
    >
      {!submitted ? (
        <>
          <Typography
            variant="h4"
            fontWeight="bold"
            gutterBottom
            align="center"
            sx={{
              background: "linear-gradient(45deg, #FF3258, #FF8B3D)",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              color: "transparent",
              mb: { xs: 1.5, sm: 3 },
              fontSize: { xs: "1.5rem", sm: "2.125rem" },
            }}
          >
            Where Greatness Begins!
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              required
              sx={{
                mb: { xs: 1.5, sm: 3 },
                "& .MuiOutlinedInput-root": {
                  height: { xs: "45px", sm: "56px" },
                  "&:hover fieldset": {
                    borderColor: "#FF3258",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#FF3258",
                  },
                },
                "& .MuiInputLabel-root": {
                  fontSize: { xs: "0.9rem", sm: "1rem" },
                },
              }}
            />
            <TextField
              label="Email"
              value={email}
              fullWidth
              required
              sx={{
                mb: { xs: 1.5, sm: 3 },
                backgroundColor: "#f5f5f5",
                "& .MuiOutlinedInput-root": {
                  height: { xs: "45px", sm: "56px" },
                },
                "& .MuiInputLabel-root": {
                  fontSize: { xs: "0.9rem", sm: "1rem" },
                },
                "& .MuiInputBase-input": {
                  color: "rgba(0, 0, 0, 0.6)",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(0, 0, 0, 0.3)",
                },
              }}
            />
            <TextField
              label="Organization Name"
              value={organization}
              onChange={(e) => setOrganization(e.target.value)}
              fullWidth
              required
              sx={{
                mb: { xs: 1.5, sm: 3 },
                "& .MuiOutlinedInput-root": {
                  height: { xs: "45px", sm: "56px" },
                  "&:hover fieldset": {
                    borderColor: "#FF3258",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#FF3258",
                  },
                },
                "& .MuiInputLabel-root": {
                  fontSize: { xs: "0.9rem", sm: "1rem" },
                },
              }}
            />
            <Button
              type="submit"
              variant="contained"
              fullWidth
              disabled={isSubmitting}
              sx={{
                mt: { xs: 1, sm: 2 },
                height: { xs: "45px", sm: "56px" },
                background: "linear-gradient(45deg, #FF3258, #FF8B3D)",
                color: "white",
                textTransform: "none",
                fontSize: { xs: "1rem", sm: "1.1rem" },
                fontWeight: "bold",
                transition: "all 0.3s ease",
                "&:hover": {
                  transform: "translateY(-2px)",
                  boxShadow: "0 8px 20px rgba(255,50,88,0.3)",
                },
              }}
            >
              {isSubmitting ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "We'll be in touch soon!"
              )}
            </Button>
          </form>
        </>
      ) : (
        <Fade in={submitted}>
          <Box
            sx={{
              textAlign: "center",
              py: 4,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                color: "#FF3258",
                fontWeight: "bold",
                mb: 2,
              }}
            >
              Thank you for registering!
            </Typography>
            <Typography color="text.secondary">
              We'll contact you shortly at {email}
            </Typography>
          </Box>
        </Fade>
      )}
    </Box>
  );
}

export default RegistrationModal;
