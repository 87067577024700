import React from 'react'
import HeroSection2 from './HeroSection2'

function HomePage2() {
    return (
        <>
            <HeroSection2 />
        </>
    )
}

export default HomePage2