import React, { useState } from "react";
import { Box, Typography, Button, Modal, Backdrop, Fade } from "@mui/material";
import imgDashboard from "../assets/image.png";
import emailjs from "emailjs-com";
import RegistrationModal from "./RegistrationModal";
import emailJSConfig from "../utils/emailJS";

function HeroSection() {
  // State variables for email input, modal display, and feedback message
  const [email, setEmail] = useState("");
  const [showRegistration, setShowRegistration] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState(""); // Combined feedback state

  // Functions to handle modal open/close
  const handleOpen = () => setShowRegistration(true);
  const handleClose = () => setShowRegistration(false);

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Check if email is empty
    if (email === "") {
      setFeedbackMessage("Please enter your email address.");
      return;
    }

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setFeedbackMessage("Please enter a valid email address.");
      return;
    }

    // Prepare form data for EmailJS
    const formData = {
      from_email: email,
      // Add any additional fields required by your EmailJS template here
    };
    handleOpen(); // Open the registration popup after setting the email
    // Send email using EmailJS
    emailjs
      .send(
        emailJSConfig.serviceID,
        emailJSConfig.templateID,
        formData,
        emailJSConfig.userID
      )
      .then((result) => {
        console.log(result.text);
        setFeedbackMessage("Thank you for joining the waitlist!");

      })
      .catch((error) => {
        console.error(error.text);
        setFeedbackMessage("An error occurred. Please try again.");
      });
  };

  return (
    <Box
      sx={{
        position: "relative",
        filter: showRegistration ? "blur(5px)" : "none",
        transition: "filter 0.3s ease-in-out",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          flexDirection: "column",
          mt: { xs: 5, md: 15 },
          px: { xs: 2, md: 0 },
        }}
      >
        {/* Announcement Box */}
        <Box
          sx={{
            bgcolor: "#f9d4db",
            px: 2,
            borderRadius: { xs: "10px", md: "25px" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#FF3258",
            fontSize: { xs: "16px", md: "20px" },
            py: 1,
            // borderRadius: '25px',
            gap: { xs: 1, md: 3 },
            flexDirection: { xs: "column", md: "row" },
            mb: 2,
          }}
        >
          <Box
            sx={{
              bgcolor: "white",
              px: 2,
              borderRadius: "25px",
              fontSize: { xs: "14px", md: "16px" },
              fontWeight: "bold",
            }}
          >
            Announcement
          </Box>
          <Box
            sx={{
              fontSize: { xs: "14px", md: "16px" },
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Introducing Campaigns
          </Box>
        </Box>

        {/* Main Content */}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          maxWidth="800px"
        >
          <Typography
            sx={{
              fontSize: { xs: "40px", sm: "50px", md: "60px" },
              fontWeight: "bold",
              textAlign: "center",
              lineHeight: 1.2,
            }}
          >
            10x Influencer Campaigns with AI Agents
          </Typography>

          <Typography
            sx={{
              fontSize: { xs: "18px", sm: "22px", md: "27px" },
              color: "gray",
              textAlign: "center",
              maxWidth: "600px",
              mt: 2,
              px: { xs: 2, md: 0 },
            }}
          >
            Qilo empowers brands with AI tools for influencer campaign
            management.
          </Typography>
        </Box>

        {/* Email Input Form */}
        <Box sx={{ width: { xs: "350px", md: "750px" } }}>
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: { xs: "15px", sm: "10px" },
                mt: 2.5,
                mb: 2.5,
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: {
                    xs: "100%",
                    md: "75%",
                  },
                }}
              >
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{
                    padding: "15px",
                    fontSize: "20px",
                    borderRadius: "10px",
                    border: "1px solid black",
                    width: "100%",
                    transition: "all 0.3s ease-in-out",
                  }}
                  onMouseOver={(e) => {
                    e.target.style.transform = "scale(1.05)";
                    e.target.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.2)";
                  }}
                  onMouseOut={(e) => {
                    e.target.style.transform = "scale(1)";
                    e.target.style.boxShadow = "none";
                  }}
                />
              </Box>
              <Button
                type="submit"
                sx={{
                  textTransform: "none",
                  fontSize: { xs: "18px", sm: "20px", md: "20px" },
                  borderTop: "1px solid black",
                  borderLeft: "1px solid black",
                  borderRight: "1px solid black",
                  borderBottom: "4px solid black",
                  borderRadius: "10px",
                  marginLeft: { xs: 0, md: "10px" },
                  py: { xs: 1.5, md: 2 },
                  px: { xs: 2, md: 3 },
                  bgcolor: "#FF3258",
                  color: "white",
                  transition: "all 0.2s ease-in-out",
                  width: { xs: "100%", md: "25%" },
                  "&:hover": {
                    transform: "scale(1.05)",
                    boxShadow: "0 4px 8px rgba(255,50,88,0.4)",
                  },
                  "&:active": {
                    transform: "scale(0.95)",
                  },
                }}
              >
                Join Waitlist
              </Button>
            </Box>
          </form>
          {/* Feedback Messages */}
          {feedbackMessage && (
            <Typography
              sx={{
                color: feedbackMessage.includes("Thank") ? "green" : "red",
                textAlign: "center",
                mt: 1,
              }}
            >
              {feedbackMessage}
            </Typography>
          )}
        </Box>

        {/* Registration Modal */}
        <Modal
          aria-labelledby="registration-modal-title"
          aria-describedby="registration-modal-description"
          open={showRegistration}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
              sx: {
                backdropFilter: "blur(5px)",
              },
            },
          }}
        >
          <Fade in={showRegistration}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 2,
                borderRadius: "10px",
                width: { xs: "90%", sm: "450px" },
                height: {
                  xs: "350px",
                  sm: "450px",
                  md: "550px",
                },
                overflowY: "auto",
                display: "flex",
              }}
            >
              <RegistrationModal email={email} onClose={handleClose} />
            </Box>
          </Fade>
        </Modal>

        {/* Hero Image Section */}
        <Box
          sx={{
            position: "relative",
            width: { xs: "95%", sm: "90%", md: "80%" },
            mt: 4,
          }}
        >
          <Box
            component="img"
            src={imgDashboard}
            alt="hero-image"
            sx={{
              width: "100%",
              height: "auto",
              borderRadius: { xs: "10px", md: "20px" },
            }}
          />
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: 0,
              right: 0,
              height: "50%",
              background:
                "linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0))",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default HeroSection;
