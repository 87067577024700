import React from 'react'
import { Box, Typography } from '@mui/material'
const Footer = () => {
    return (
        <Box 
            width='100%' 
            bgcolor='black' 
            minHeight={{ xs: '600px', md: '200px' }}
            display='flex' 
            justifyContent='center' 
            alignItems='flex-start'
            flexDirection={{ xs: 'column', md: 'row' }} 
            p={2}
            gap={{ xs: 4, md: 0 }}
        >
            <Box 
                width={{ xs: '100%', md: '25%' }} 
                display='flex' 
                justifyContent='center' 
                alignItems={{ xs: 'flex-start', md: 'flex-start' }}
                flexDirection='column' 
                p={2}
            >
                <Typography sx={{ fontSize: { xs: '1.5rem', md: '2rem' } }} fontWeight='bold' color='white'>Qilo</Typography>
                <Typography sx={{ fontSize: { xs: '16px', md: '20px' } }} fontWeight='normal' color='gray'>The best way to find, create, and manage influencer campaigns</Typography>
            </Box>
            <Box 
                width={{ xs: '100%', md: '40%' }} 
                display='flex' 
                justifyContent='center' 
                alignItems='left' 
                flexDirection='column' 
                p={2}
            >
                <Typography sx={{ fontSize: { xs: '1.25rem', md: '1.5rem' } }} fontWeight='bold' color='white'>Address</Typography>
                <Typography sx={{ fontSize: { xs: '16px', md: '20px' } }} fontWeight='normal' color='gray'>4th Floor, WeWork Zenia, Hiranandani Business Park, Thane West, Thane, Maharashtra - 400607</Typography>
            </Box>
            <Box 
                width={{ xs: '100%', md: '10%' }} 
                display='flex' 
                justifyContent='center' 
                alignItems='left' 
                flexDirection='column' 
                p={2}
            >
                <Typography sx={{ fontSize: { xs: '1.25rem', md: '1.5rem' } }} fontWeight='bold' color='white'>Product</Typography>
                <Typography sx={{ fontSize: { xs: '16px', md: '20px' } }} fontWeight='normal' color='gray'>Login</Typography>
                <Typography sx={{ fontSize: { xs: '16px', md: '20px' } }} fontWeight='normal' color='gray'>Sign Up</Typography>
                {/* <Typography fontSize='15px' fontWeight='normal' color='black'>Contact Us</Typography>    */}
            </Box>
            <Box 
                width={{ xs: '100%', md: '25%' }} 
                display='flex' 
                justifyContent='center' 
                alignItems='left' 
                flexDirection='column' 
                p={2}
            >
                <Typography sx={{ fontSize: { xs: '1.25rem', md: '1.5rem' } }} fontWeight='bold' color='white'>Contact</Typography>
                <Typography sx={{ fontSize: { xs: '16px', md: '20px' } }} fontWeight='normal' color='gray'>customer@qilo.in</Typography>
            </Box>
        </Box>
    )
}

export default Footer