import React from 'react'
import { Box, Typography } from '@mui/material'
import imgFeature1 from '../assets/main-screen-2.png';
import imgFeature2 from '../assets/main-screen.png';
import imgFeature3 from '../assets/analyze-screen.png';


const Section3 = () => {
    return (
        <Box width='100%' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', bgcolor: 'rgba(128, 128, 128, 0.1)', py: 10 }}>
            <Typography sx={{ fontSize: { xs: '16px', md: '20px' }, color: 'red' }}>Our Solution</Typography>
            <Typography sx={{ fontSize: { xs: '30px', md: '60px' }, textAlign: 'center', px: 2 }}>Revolutionize Your Marketing with AI</Typography>
            <Typography sx={{ fontSize: { xs: '16px', md: '20px' }, color: 'gray', maxWidth: '600px', textAlign: 'center', px: 2 }}>Qilo connects brands with impactful creators, delivering data-driven campaigns that drive exceptional results.</Typography>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                px: { xs: 2, md: 0 }
            }}>
                <Box sx={{
                    display: 'flex',
                    width: { xs: '100%', md: '60%' },
                    gap: { xs: 2, md: 4 },
                    mt: 8,
                    height: { xs: 'auto', md: '100vh' },
                    overflow: 'hidden',
                    flexDirection: { xs: 'column', md: 'row' }
                }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, gap: { xs: 2, md: 4 } }}>
                        <Box sx={{
                            bgcolor: 'white',
                            color: '#FF3258',
                            borderRadius: '16px',
                            p: { xs: 2, md: 4 },
                            minHeight: { xs: '150px', md: '200px' },
                            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                            transition: 'background-color 0.5s ease',
                            '&:hover': {
                                background: 'linear-gradient(to bottom, rgba(255, 50, 88, 0.1), rgba(255, 50, 88, 0))'
                            }
                        }}>
                            <Box sx={{ fontSize: { xs: '18px', md: '20px' }, fontWeight: 'bold' }}>Campaign Management Simplified</Box>
                            <Box sx={{ fontSize: { xs: '14px', md: '16px' }, color: 'gray' }}>Generate detailed campaign briefs, optimize content ideas, and manage workflows efficiently.</Box>
                            <Box sx={{ position: 'relative' }}>
                                <Box component="img" src={imgFeature1} alt="feature1" sx={{
                                    width: '100%',
                                    height: 'auto',
                                    mt: 2.5,
                                    borderRadius: '10px',
                                    display: 'block'
                                }} />
                                <Box sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    background: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))'
                                }} />
                            </Box>
                        </Box>
                        <Box sx={{
                            bgcolor: 'white',
                            borderRadius: '16px',
                            color: '#FF3258',
                            p: { xs: 2, md: 4 },
                            minHeight: { xs: '150px', md: '200px' },
                            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                            transition: 'background-color 0.5s ease',
                            '&:hover': {
                                background: 'linear-gradient(to bottom, rgba(0, 50, 255, 0.1), rgba(0, 50, 255, 0))'
                            }
                        }}>
                            <Box sx={{ fontSize: { xs: '18px', md: '20px' }, fontWeight: 'bold' }}>Data-Driven Campaigns</Box>
                            <Box sx={{ fontSize: { xs: '14px', md: '16px' }, color: 'gray' }}>Our platform ensures your campaigns are backed by data, driving exceptional results.</Box>
                            <Box sx={{ position: 'relative' }}>
                                <Box component="img" src={imgFeature2} alt="feature1" sx={{
                                    width: '100%',
                                    height: 'auto',
                                    mt: 2.5,
                                    borderRadius: '10px',
                                    display: 'block'
                                }} />
                                <Box sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    background: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))'
                                }} />
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{
                        bgcolor: 'white',
                        borderRadius: '16px',
                        color: '#FF3258',
                        padding: { xs: "20px", md: "40px 0px 40px 40px" },
                        flex: 1,
                        minHeight: { xs: '300px', md: '424px' },
                        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                        overflow: 'hidden',
                        transition: 'background-color 0.5s ease',
                        '&:hover': {
                            background: 'linear-gradient(to bottom, rgba(255, 165, 0, 0.1), rgba(255, 165, 0, 0))'
                        }
                    }}>
                        <Box sx={{ fontSize: { xs: '18px', md: '20px' }, fontWeight: 'bold' }}>Performance Analytics</Box>
                        <Box sx={{ fontSize: { xs: '14px', md: '16px' }, color: 'gray' }}>Track reach, engagement, and ROI with automated performance reports and actionable insights.</Box>
                        <Box sx={{ position: 'relative', flexGrow: 1, overflow: 'hidden', paddingTop: { xs: 2, md: 5 }, height: '100%' }}>
                            <Box position='relative' sx={{ height: '100%' }}>
                                <Box component="img" src={imgFeature3} alt="feature1" sx={{
                                    width: '100%',
                                    height: '100%',
                                    mt: 2.5,
                                    ml: { xs: 0, md: 5 },
                                    objectFit: 'cover',
                                    objectPosition: 'left center',
                                    borderRadius: '10px',
                                    display: 'block'
                                }} />
                                <Box sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: '-20%', // Extend gradient beyond image
                                    bottom: 0,
                                    background: {
                                        xs: 'linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 90%)',
                                        md: 'linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 90%)'
                                    }
                                }} />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Section3