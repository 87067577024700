import React from 'react'
import { Box, Typography, Button } from '@mui/material'
import imgProfile from '../assets/creator-profile.png';
function HeroSection2() {
    return (
        <>
            <Box width='100%' display='flex' justifyContent='start' alignItems='center' mt={{ xs: 5, md: 10 }} flexDirection='column' gap={2}>
                <Box width={{ xs: '90%', md: '80%' }} maxWidth='650px' display='flex' flexDirection='column' gap={2} alignItems='center' >
                    <Typography sx={{ fontSize: { xs: '40px', md: '70px' } }} fontWeight='bold' textAlign='center'>Media Kits for Creators that work</Typography>
                    <Typography sx={{ fontSize: { xs: '16px', md: '20px' } }} fontWeight='normal' textAlign='center'>We make it easy for you to create and share your media kits with brands. We have a library of templates to get you started, and you can customize them to your liking.</Typography>
                    <Button sx={{ 
                        textTransform: 'none', 
                        fontSize: { xs: '18px', md: '25px' }, 
                        borderTop: '1px solid black', 
                        borderLeft: '1px solid black', 
                        borderRight: '1px solid black', 
                        borderBottom: '4px solid black', 
                        borderRadius: '10px', 
                        py: { xs: 1.5, md: 2 }, 
                        px: { xs: 2, md: 3 }, 
                        bgcolor: '#FF3258', 
                        color: 'white', 
                        mt: 4, 
                        transition: 'all 0.2s ease-in-out', 
                        '&:hover': { 
                            bgcolor: '#FF3258', 
                            transform: 'scale(1.05)' 
                        } 
                    }}>Create Profile for free</Button>
                </Box>
                <Box width={{ xs: '90%', md: '80%' }} height='auto' display='flex' justifyContent='center' alignItems='center'>
                    <Box component='img' src={imgProfile} alt='dashboard' width='100%' height='auto' objectFit='contain' />
                </Box>
                <Box width={{ xs: '90%', md: '80%' }} minHeight='20vh' display='flex' flexDirection={{ xs: 'column', md: 'row' }} gap={4} alignItems='center' sx={{ '& > *': { flex: 1 }, mt: { xs: 5, md: 10 } }}>
                    <Box textAlign='left'>
                        <Typography sx={{ fontSize: { xs: '28px', md: '40px' } }} fontWeight='bold' textAlign={{ xs: 'center', md: 'left' }}>Your metrics updated in real time</Typography>
                        <Typography sx={{ fontSize: { xs: '20px', md: '30px' } }} fontWeight='normal' textAlign={{ xs: 'center', md: 'left' }} color='gray'>Connect your profiles and never worry about updating your numbers before sending your media kit.</Typography>
                    </Box>
                    <Box textAlign='left'>
                        <Box component='img' src={imgProfile} alt='dashboard' width='100%' height='auto' objectFit='contain' />
                    </Box>
                </Box>
                <Box width={{ xs: '90%', md: '80%' }} minHeight='20vh' display='flex' flexDirection={{ xs: 'column-reverse', md: 'row' }} gap={4} alignItems='center' sx={{ '& > *': { flex: 1 }, mt: { xs: 5, md: 10 } }}>
                    <Box textAlign='left'>
                        <Box component='img' src={imgProfile} alt='dashboard' width='100%' height='auto' objectFit='contain' />
                    </Box>
                    <Box textAlign='left'>
                        <Typography sx={{ fontSize: { xs: '28px', md: '40px' } }} fontWeight='bold' textAlign={{ xs: 'center', md: 'left' }}>Your metrics updated in real time</Typography>
                        <Typography sx={{ fontSize: { xs: '20px', md: '30px' } }} fontWeight='normal' textAlign={{ xs: 'center', md: 'left' }} color='gray'>Connect your profiles and never worry about updating your numbers before sending your media kit.</Typography>
                    </Box>
                </Box>
                <Box width='100%' bgcolor='black' minHeight={{ xs: '40vh', md: '50vh' }} display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                    <Box width={{ xs: '90%', md: '650px' }} height='auto' display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                        <Typography sx={{ fontSize: { xs: '28px', md: '40px' } }} fontWeight='bold' textAlign='center' color='white'>Ready to automate your workflow?</Typography>
                        <Button sx={{ 
                            textTransform: 'none', 
                            fontSize: { xs: '18px', md: '25px' }, 
                            borderTop: '1px solid black', 
                            borderLeft: '1px solid black', 
                            borderRight: '1px solid black', 
                            borderBottom: '4px solid black', 
                            borderRadius: '10px', 
                            py: { xs: 1.5, md: 2 }, 
                            px: { xs: 2, md: 3 }, 
                            bgcolor: '#FF3258', 
                            color: 'white', 
                            mt: 4, 
                            transition: 'all 0.2s ease-in-out', 
                            '&:hover': { 
                                bgcolor: '#FF3258', 
                                transform: 'scale(1.05)' 
                            } 
                        }}>Get Started</Button>
                    </Box>
                </Box>
            </Box>
            
        </>
    )
}

export default HeroSection2