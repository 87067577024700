import React, { useState } from 'react'
import { AppBar, Toolbar, Typography, Button } from '@mui/material'
import { Box } from '@mui/material'
import logo from '../assets/logo.png'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useMediaQuery } from '@mui/material';


function NavBar() {
    const isMobile = useMediaQuery('(max-width:600px)');
    const [showNavBar, setShowNavBar] = useState(isMobile ? false : true);





    return (
        <>
            <AppBar position="static" sx={{
                background: 'transparent',
                boxShadow: 'none',
                height: { xs: 'auto', md: '100px' },
                display: 'flex',
                justifyContent: 'end'
            }}>
                {isMobile && <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mr: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0 }}>
                        <Box component="img" src={logo} alt="Logo" sx={{
                            height: { xs: 60, md: 80 }
                        }} />
                    </Box>
                    <Button sx={{ color: 'black' }} onClick={() => setShowNavBar(!showNavBar)}>{showNavBar ? <CloseIcon /> : <MenuIcon />}</Button>
                </Box>}
                {
                    showNavBar &&
                    <Toolbar sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        justifyContent: 'space-between',
                        color: 'black',
                        gap: { xs: 2, md: 0 },
                        py: { xs: 2, md: 0 }
                    }}>
                        {!isMobile && <Box sx={{ display: 'flex', alignItems: 'center', gap: 0 }}>
                            <Box component="img" src={logo} alt="Logo" sx={{
                                height: { xs: 60, md: 80 }
                            }} />
                        </Box>}

                        <Box sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            gap: { xs: 2, md: 4 },
                            alignItems: 'center',
                            width: { xs: '100%', md: 'auto' }
                        }}>
                            <Button color="inherit" sx={{
                                textTransform: 'none',
                                fontSize: { xs: '16px', md: '20px' },
                                width: { xs: '100%', md: 'auto' }
                            }} onClick={() => window.location.href = '/'}>For Brands</Button>
                            <Box sx={{ position: 'relative' }}>
                                <Button
                                    color="inherit"
                                    sx={{
                                        textTransform: 'none',
                                        fontSize: { xs: '16px', md: '20px' },
                                        width: { xs: '100%', md: 'auto' }
                                    }}
                                    onClick={() => window.location.href = '/'}
                                >
                                    For Creators
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: -10,
                                            right: -15,
                                            background: '#D3D3D3',
                                            color: 'black',
                                            padding: '4px 8px',
                                            borderRadius: '12px',
                                            fontSize: '10px',
                                            // fontWeight: 'bold',
                                            textTransform: 'uppercase',
                                            letterSpacing: '0.5px',
                                            boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                                            // animation: 'pulse 2s infinite',
                                            // '@keyframes pulse': {
                                            //     '0%': { transform: 'scale(1)' },
                                            //     '50%': { transform: 'scale(1.05)' },
                                            //     '100%': { transform: 'scale(1)' }
                                            // }
                                        }}
                                    >
                                        Coming Soon
                                    </Box>
                                </Button>
                            </Box>
                            {/* <Button color="inherit" sx={{
                                textTransform: 'none',
                                fontSize: { xs: '16px', md: '20px' },
                                width: { xs: '100%', md: 'auto' }
                            }}>FAQ</Button> */}
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            gap: 2,
                            flexDirection: { xs: 'column', md: 'row' },
                            width: { xs: '100%', md: 'auto' }
                        }}>
                            <Button color="inherit" sx={{
                                textTransform: 'none',
                                fontSize: '16px',
                                borderTop: '1px solid black',
                                borderLeft: '1px solid black',
                                borderRight: '1px solid black',
                                borderBottom: '2px solid black',
                                borderRadius: '10px',
                                py: 1,
                                px: 2,
                                bgcolor: '#D3D3D3',
                                color: 'black',
                                width: { xs: '100%', md: 'auto' }
                            }}>Alpha Launch Soon</Button>
                            {/* <Button color="inherit" sx={{
                                textTransform: 'none',
                                fontSize: '16px',
                                border: '1px solid black',
                                borderRadius: '10px',
                                py: 1,
                                px: 2,
                                width: { xs: '100%', md: 'auto' }
                            }}>Sign In</Button> */}
                        </Box>
                    </Toolbar>
                }
            </AppBar>
        </>
    )
}

export default NavBar