import React from 'react'
import { Box, Typography } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBrain } from '@fortawesome/free-solid-svg-icons'
import { BsFillLightningChargeFill } from "react-icons/bs";
import { MdOutlineSecurity } from "react-icons/md";

const Section2 = () => {
    return (
        <Box width='100%' sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            flexDirection: 'column',
            px: { xs: 2, md: 0 }
        }}>
            <Typography sx={{ 
                fontSize: { xs: '16px', md: '20px' }, 
                color: 'red' 
            }}>Problem</Typography>
            <Typography sx={{ 
                fontSize: { xs: '35px', sm: '45px', md: '60px' },
                textAlign: 'center'
            }}>Manually entering your data is a hassle.</Typography>
            <Box mt={{ xs: 5, md: 10 }} width='100%' mb={{ xs: 10, md: 20 }}>
                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    flexDirection: { xs: 'column', md: 'row' }, 
                    gap: { xs: 8, md: 15 }
                }}>
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        flexDirection: 'column', 
                        gap: 1 
                    }}>
                        <Box sx={{ 
                            fontSize: { xs: '30px', md: '40px' }, 
                            color: '#FF3258', 
                            bgcolor: 'rgba(249, 212, 219, 0.7)', 
                            width: { xs: '60px', md: '70px' }, 
                            height: { xs: '60px', md: '70px' }, 
                            borderRadius: '50%', 
                            display: 'flex', 
                            justifyContent: 'center', 
                            alignItems: 'center' 
                        }}><FontAwesomeIcon icon={faBrain} /></Box>
                        <Box sx={{ 
                            fontSize: { xs: '24px', md: '30px' },
                            textAlign: 'center'
                        }}>Data Overload</Box>
                        <Box sx={{ 
                            fontSize: { xs: '16px', md: '20px' }, 
                            color: 'gray', 
                            maxWidth: '400px', 
                            textAlign: 'center',
                            px: { xs: 2, md: 0 }
                        }}>Identifying the right influencers involves analyzing vast amounts of data, making the process complex and time-consuming.</Box>
                    </Box>
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        flexDirection: 'column', 
                        gap: 1 
                    }}>
                        <Box sx={{ 
                            fontSize: { xs: '30px', md: '40px' }, 
                            color: '#FF3258', 
                            bgcolor: 'rgba(249, 212, 219, 0.7)', 
                            width: { xs: '60px', md: '70px' }, 
                            height: { xs: '60px', md: '70px' }, 
                            borderRadius: '50%', 
                            display: 'flex', 
                            justifyContent: 'center', 
                            alignItems: 'center' 
                        }}><BsFillLightningChargeFill /></Box>
                        <Box sx={{ 
                            fontSize: { xs: '24px', md: '30px' },
                            textAlign: 'center'
                        }}>Slow Decision-Making</Box>
                        <Box sx={{ 
                            fontSize: { xs: '16px', md: '20px' }, 
                            color: 'gray', 
                            maxWidth: '400px', 
                            textAlign: 'center',
                            px: { xs: 2, md: 0 }
                        }}>Without AI, planning and executing campaigns often leads to delayed insights and missed opportunities.</Box>
                    </Box>
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        flexDirection: 'column', 
                        gap: 1 
                    }}>
                        <Box sx={{ 
                            fontSize: { xs: '30px', md: '40px' }, 
                            color: '#FF3258', 
                            bgcolor: 'rgba(249, 212, 219, 0.7)', 
                            width: { xs: '60px', md: '70px' }, 
                            height: { xs: '60px', md: '70px' }, 
                            borderRadius: '50%', 
                            display: 'flex', 
                            justifyContent: 'center', 
                            alignItems: 'center' 
                        }}><MdOutlineSecurity /></Box>
                        <Box sx={{ 
                            fontSize: { xs: '24px', md: '30px' },
                            textAlign: 'center'
                        }}>Fraud Risks</Box>
                        <Box sx={{ 
                            fontSize: { xs: '16px', md: '20px' }, 
                            color: 'gray', 
                            maxWidth: '400px', 
                            textAlign: 'center',
                            px: { xs: 2, md: 0 }
                        }}>Ensuring authenticity and avoiding influencers with fake followers is challenging without advanced tools.</Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Section2